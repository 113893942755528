<template>
  <portal to="ai-box-portal">
    <div class="ai-box-wrap" @click="closeModal"></div>
    <!-- deleteTask -->
    <div v-if="paramAiboxPortal.info === 'deleteTask'" class="ai-box-modal">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_delete_data') }}
      </div>
      <div class="content">
        <div class="title">{{ $t('setting_aibox_delete_aibox_task') }}</div>
        <div class="data">
          <div class="items">
            <div class="col">{{ $t('ai_box') }}</div>
            <div class="col">{{ $t('ai_device') }}</div>
            <div class="col">{{ $t('ai_recognition_category') }}</div>
            <div class="col">{{ $t('ai_recognition_status') }}</div>
            <div class="col">{{ $t('ai_updated_time') }}</div>
          </div>
          <div class="value">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="aiBoxTask.aiboxName" placement="bottom">
              <div class="col">{{ aiBoxTask.aiboxName }}</div>
            </el-tooltip>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="deviceName" placement="bottom">
              <div class="col">{{ deviceName }}</div>
            </el-tooltip>
            <div class="col">
              <img src="@/assets/icons/object.svg" alt="">
              <template v-if="aiBoxTask.ai === 'or'">{{ $t('ai_object')/*物件*/ }}</template>
              <template v-else-if="aiBoxTask.ai === 'lpr'">{{ $t('ai_license_plate')/*車牌*/ }}</template>
              <template v-else>{{ aiBoxTask.ai }}</template>
            </div>
            <!-- <div class="col">{{ aiBoxTask.status }}</div> -->
            <div v-if="aiBoxTask.id === -99" class="col">{{ $t('ai_task_add') }}</div>
            <div v-else-if="isEdit && aiBoxTask.id >= 0 && aiBoxTask.id === taskId" class="col">{{ $t('ai_task_edit') }}</div>
            <div v-else-if="aiBoxTask.status === 0" class="col">{{ $t(`ai_status_${aiBoxTask.status}`) }}</div>
            <div v-else class="col">
              <img src="@/assets/icons/error-info.svg" alt="">{{ $t(`ai_status_${aiBoxTask.status}`) }}
            </div>
            <div class="col">{{ formatTime(aiBoxTask.updatedTime) }}</div>
          </div>
        </div>
        <div class="btns">
          <div class="btn" @click="closeModal">{{ $t('cancel') }}</div>
          <div class="btn red" @click="handleDelete">{{ $t('delete') }}</div>
        </div>
      </div>
    </div>
    <!-- cancelEdit -->
    <div v-else-if="paramAiboxPortal.info === 'cancelEdit'" class="ai-box-modal small">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_edit_not_complete')/*編輯未完成*/ }}
      </div>
      <div class="content">
        <div class="title">{{ $t('ai_cancel_edit')/*確定取消此編輯嗎？*/ }}</div>
        <div class="btns">
          <div class="btn" @click="closeModal">{{ $t('no') }}</div>
          <div class="btn red" @click="handleCancelEdit">{{ $t('yes') }}</div>
        </div>
      </div>
    </div>
    <!-- resourceNotAvailable -->
    <div v-else-if="paramAiboxPortal.info === 'resourceNotAvailable'" class="ai-box-modal small">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_insufficient_recognition_resources')/*辨識資源不足*/ }}
      </div>
      <div class="content">
        <div class="title">{{ $t('ai_no_boxes_available')/*很抱歉，無可使用的辨識盒。*/ }}</div>
        <div class="btns">
          <div class="btn yellow" @click="handleConfirm">{{ $t('confirm') }}</div>
        </div>
      </div>
    </div>
    <!-- newTaskAdded -->
    <div v-else-if="paramAiboxPortal.info === 'newTaskAdded'" class="ai-box-modal small">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_new_recognition')/*新增辨識任務*/ }}
      </div>
      <div class="content">
        <div class="title">{{ $t('ai_task_already_added')/*已有新增的辨識任務，請設定完成後再新增。*/ }}</div>
        <div class="btns">
          <div class="btn yellow" @click="handleConfirm">{{ $t('confirm') }}</div>
        </div>
      </div>
    </div>
    <!-- frScoreAdjust -->
    <div v-else-if="paramAiboxPortal.info === 'frScoreAdjust'" class="ai-box-modal small">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_parameter_adjustment')/*參數調整*/ }}
      </div>
      <div class="content">
        <div class="info">{{ $t('ai_event_score_greater_than')/*事件觸發分數需大於等於事件候選分數*/ }}</div>
        <div class="info" v-if="paramAiboxPortal.data.adjustKey === 'candidateScore' || paramAiboxPortal.data.adjustKey === 'candidateScoreWithMask'">
          {{ $t('ai_candidate_score_adjust', {score: paramAiboxPortal.data.adjustScore})/*事件候選分數調整為 {score}*/ }}
        </div>
        <div class="info" v-else-if="paramAiboxPortal.data.adjustKey === 'eventScore' || paramAiboxPortal.data.adjustKey === 'eventScoreWithMask'">
          {{ $t('ai_event_score_adjust', {score: paramAiboxPortal.data.adjustScore})/*事件觸發分數調整為 {score}*/ }}
        </div>
        <div class="btns">
          <div class="btn yellow" @click="handleConfirm">{{ $t('confirm') }}</div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'AiboxPortal',
  data() {
    return {}
  },
  computed: {
    ...mapState('aibox', ['isEdit', 'paramAiboxPortal']),
    ...mapGetters('aibox', ['aiBoxTask', 'deviceName'])
  },
  methods: {
    ...mapMutations('aibox', [
      'updateAiRunOn', 
      'updateAiMode', 
      'updateShowAiboxPortal', 
      'updateIsEdit', 
      'updateTaskId', 
      'updateShowAiBox'
    ]),
    ...mapActions('aibox', ['getAiBoxes', 'deleteAiBoxTask', 'getAiBoxTasks']),
    formatTime,
    closeModal() {
      this.updateShowAiboxPortal(false)
    },
    handleDelete() {
      this.deleteAiBoxTask()
      this.updateIsEdit(false)
      this.updateShowAiboxPortal(false)
    },
    async handleCancelEdit() {
      switch (this.paramAiboxPortal.action) {
        case 'closeModal':
          this.updateShowAiBox(false)
          break
        case 'switchTask':
          this.updateIsEdit(false)
          this.updateAiRunOn(this.paramAiboxPortal.payload.aiRunOn)
          this.updateAiMode(this.paramAiboxPortal.payload.aiMode)
          if (this.paramAiboxPortal.payload.aiRunOn === 'aibox') {
            this.updateTaskId(this.paramAiboxPortal.payload.taskId)
            this.getAiBoxes() // 取得辨識盒資源
          }
          this.getAiBoxTasks() // 重新取得AiBoxTasks
          break
        case 'cancelFrEdit':
          this.updateIsEdit(false)
          await this.$store.dispatch('recgFr/getUserInfo')
          this.$store.dispatch('recgFr/initSetting')
          this.$store.dispatch('recgFr/getUserSubscribers')
          break
        case 'cancelDeviceLprEdit':
          this.updateIsEdit(false)
          await this.$store.dispatch('recgLpr/getUserInfo')
          this.$store.dispatch('recgLpr/initSetting')
          this.$store.dispatch('recgLpr/getUserSubscribers')
          break
        case 'cancelAiboxLprEdit':
          this.updateIsEdit(false)
          this.getAiBoxTasks() // 重新取得AiBoxTasks
          break
        case 'cancelDeviceOrEdit':
          this.$store.dispatch('recgOr/initRoiSetting')
          await this.$store.dispatch('recgOr/getUserInfo')
          this.$store.dispatch('recgOr/initSetting')
          this.$store.dispatch('recgOr/getUserSubscribers')
          this.updateIsEdit(false)
          break
        case 'cancelAiboxOrEdit':
          this.updateIsEdit(false)
          this.getAiBoxTasks() // 重新取得AiBoxTasks
          break
        default:
          break
      }
      this.updateShowAiboxPortal(false)
    },
    handleConfirm() {
      this.updateShowAiboxPortal(false)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.ai-box-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000b3;
  z-index: 10;
}

.ai-box-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-size: px2rem(16);
  font-weight: 400;
  line-height: px2rem(24);
  z-index: 10;

  .header {
    background: #4a5c78;
    color: #ffffff;
    border-radius: 23px 23px 0px 0px;
    font-size: px2rem(20);
    line-height: 30px;
    font-weight: 400;
    padding: 12px 32px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 22px;
    }
  }
}

.ai-box-modal.small {
  width: 500px;
}

.content {
  background: #282942;
  border-radius: 0px 0px 23px 23px;
  
  position: relative;
  padding: 20px 32px 32px 32px;
  .info {
    font-size: 18px;
    line-height: 44px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    &:nth-child(2) {
      margin-bottom: 32px;
    }
  }
}

.content .title {
  font-size: 18px;
  line-height: 44px;
  font-weight: 400;
  color: #ffffff;
  padding-bottom: 32px;
  text-align: center;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  display: inline-block;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  border : 1px solid #ffffff;
  padding: 10px 48px;
  cursor: pointer;
}

.items {
  border-bottom: 1px solid #4A5C78;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
}

.value {
  display: flex;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 60px;
}

.col {
  padding-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.col:nth-child(1) {
  width: 20%;
  padding-left: 30px;
}

.col:nth-child(2) {
  width: 18%;
}

.col:nth-child(3) {
  width: 15%;
}

.col:nth-child(4) {
  width: 23%;
}

.col:nth-child(5) {
  width: 23%;
}

.col img {
  position: relative;
  top: 4px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.btn:hover {
  background: #ffffff33;
}

.red {
  background: #F94144;
  border-color: #F94144;
}

.red:hover {
  background: #F94144cc;
}

.btn.yellow {
  border: #ffc600 1px solid;
  background: #ffc600;
  color: #ffffff;
}

.btn.yellow:hover {
  background: #ffd231;
}

.btns .btn:not(:last-child) {
  margin-right: 48px;
}

/* .btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
} */
</style>